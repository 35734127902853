<template>
  <div>
    <div class="editProduct">
      <div class="editProduct_type"   v-loading="!loading"
      element-loading-text="正正加载,请稍后...">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <essential-information
              :essentialInfor.sync="editData"
              :typtchange="typtchange"
              ref="essential"
              :enterpriseType="enterpriseType"
            />
          </el-tab-pane>
          <el-tab-pane label="详细信息" name="second">
            <detailed-information
              ref="detailed"
              :detailedInfo.sync="editData"
              :enterpriseType="enterpriseType"
              :productType="editData.productType"
            />
          </el-tab-pane>
          <el-tab-pane label="价格说明" name="third">
            <price-description
              :productCode.sync="productCode"
              @pricelClick="pricelClick"
            />
          </el-tab-pane>
          <el-tab-pane label="标签信息" name="four">
            <label-info
              ref="labelInfo"
              :enterpriseType="enterpriseType"
            ></label-info>
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="five">
            <labelManagement
              ref="labelManagement"
              @clickload="clickload"
              :isload="loading"
              :enterpriseType="enterpriseType"
              :productCode="productCode"
            ></labelManagement>
          </el-tab-pane>
          <el-tab-pane label="半自动化标签" name="six">
            <robotizationlabel
              ref="robotizationlabel"
              :enterpriseType="enterpriseType"
              :companyCode="productCode"
            ></robotizationlabel>
          </el-tab-pane>
          <!-- <el-tab-pane label="售后说明" name="four">
            <after-description :afterInfo.sync="editData" :enterpriseType="enterpriseType" />
          </el-tab-pane> -->
          <!-- <el-tab-pane label="产品附件" name="five">
            <product-accessories
              :productCode.sync="productCode"
              @newClick="newClick"
            />
          </el-tab-pane> -->
        </el-tabs>
        <div
          style="height: 50px; background: #fff;border-top: 1px solid #dbdbdb"
          v-if="this.activeName == 'six'"
        ></div>
        <div class="bottom" v-if="this.activeName !== 'six'">
          <el-button type="primary" @click="addProduct">确定</el-button>
          <el-button type="info" @click="cancelClick">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入子组件
import essentialInformation from "./essentialInformation";
import detailedInformation from "./detailedInformation";
import priceDescription from "./priceDescription";
import afterDescription from "./afterDescription";
import productAccessories from "./productAccessories";
import labelInfo from "./labelInfo";
import labelManagement from "./labelManagement";
import robotizationlabel from "./robotizationlabel.vue";
// 查询单个 / 新增产品数据 / 产品基本信息修改 / 产品标签-新增 / 编辑产品标签 / 产品标签信息详情 / 手动标签新增 / 手动打标签编辑
import {
  getFindOne,
  addProduct,
  updateEssenti,
  insertProductLabel,
  updateProductLabel,
  selectProductLabel,
  insertProductLabelManual,
  updatePlanLabelManual,
} from "../../../api/product";
import { reportSave } from "../../../api/content";
export default {
  name: "ProductInfo",
  components: {
    essentialInformation,
    detailedInformation,
    priceDescription,
    afterDescription,
    productAccessories,
    labelInfo,
    labelManagement,
    robotizationlabel,
  },
  data() {
    return {
      loading:false,
      activeName: "first", // 当前标签页
      productCode: "", // 数据唯一id
      editData: {
        // 包含（基本信息、详细信息）初始表单
        companyCode: "", //所属企业编号
        productCode: this.productCode, //数据唯一id
        // companyCode: this.productCode ? this.productCode : "", //所属企业编号
        companyFullName: "", //企业全称
        oldSolutionImg: [],
        solutionImg: [],
        solutionIndustry: [], //行业类别
        solutionIndustryChild: [], //行业类别子标签
        domainCategory: [], // 领域类别
        domainCategoryChild: [], //领域类别子标签
        solutionTechnology: [], // 技术类别
        solutionTechnologyChild: [], //技术类别子标签
        productTypeChild: [], //产品类型标签
        productAi: [], //技术驱动
        solutionDelivery: [], //交付类型
        productName: "", // 产品名称
        productType: "", //产品类型
        //关联企业//产品功能
        informationProducts: [
          {
            id: null, //主键
            informationId: this.productCode, //序号产品ID
            productFunctionDescribe: null, //产品功能描述
            productImage: "", //产品图片
          },
        ],
        companyWebsite: "", //所属企业链接
        productCoverList: [], //产品封面图
        productImg: [],
        shareImages: [], //分享图片
        oldShareImages: [],
        videos: null, //视频
        videoResults: null,
        // 详细信息
        productScenes: [""], //应用场景
        // 应用价值
        informationProductValues: [
          {
            id: "", //主键
            informationId: this.productCode, //资讯ID
            value: "", //30%,50%
            valueTitle: "", //价值标题
            valueType: "", //价值类型
          },
        ],
        productExplain: "", //产品说明
        productLightSpots: [""], //产品亮点
        productHexPlains: [""], //硬件说明
        productCases: [""], //合作案例
        productInvoice: "", //发票信息
        serviceGuarantee: "", //服务保障
        productFileList: [""], //产品附件
      },
      productType:'',
      priceList: [], //产品价格数据
      enclosureList: [], //产品附件数据
      pricelClickList: [], // 返回的价格说明
      newClickList: [], // 返回的产品附件
    };
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "five") {
          this.$refs.labelManagement.getBylabelId();
        }
      },
    },
  },
  mounted() {
    document.querySelector(".el-tabs__content").style.minHeight =
      document.body.clientHeight - 240 + "px";
  },
  created() {
    // 如果是编辑，就将父页面传递的表格序号赋值给productCode
    if (this.enterpriseType) {
      this.productCode = this.$route.query.productCode;
      this.seach();
      this.selectProductLabel();
    }
  },
  computed: {
    // 0 新增 1 编辑 点击新增/编辑页面按钮调整路径带过来的
    enterpriseType() {
      return Number(this.$route.query.enterpriseType);
    },
    // 数据唯一id
    productCodeTop() {
      return Number(this.$route.query.productCode);
    },
  },
  methods: {
    typtchange(val){
     
      
      this.productType=val
    },
    clickload(str){
     this.loading=str
      
    },
    //返回的价格说明
    pricelClick(val) {
      this.pricelClickList = val;
    },
    //返回的产品附件
    newClick(val) {
      this.newClickList = val;
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    //取消
    cancelClick() {
      this.$router.push({
        path: "/product",
      });
    },
    //新增产品数据
    addProduct() {
      // try {
      //   await Promise.all([
      //     this.$refs.essential.$refs.ruleFormd.validate(async (valid) => {
      //       if (valid) {
      //         // //行业
      //         // if (this.editData.solutionIndustryChild) {
      //         //   this.editData.solutionIndustry =
      //         //     this.editData.solutionIndustry.concat(
      //         //       this.editData.solutionIndustryChild
      //         //     )
      //         // }
      //         // //领域
      //         // if (this.editData.domainCategoryChild) {
      //         //   this.editData.domainCategory =
      //         //     this.editData.domainCategory.concat(
      //         //       this.editData.domainCategoryChild
      //         //     )
      //         // }
      //         // //技术
      //         // if (this.editData.solutionTechnologyChild) {
      //         //   this.editData.solutionTechnology =
      //         //     this.editData.solutionTechnology.concat(
      //         //       this.editData.solutionTechnologyChild
      //         //     )
      //         // }
      //         // //产品
      //         // if (this.editData.productTypeChild) {
      //         //   this.editData.productType = this.editData.productType.concat(
      //         //     this.editData.productTypeChild
      //         //   )
      //         // }
      //         const query = {
      //           productAddAndEditDto: this.editData,
      //           productPrice: this.pricelClickList,
      //           // productFile: this.newClickList,
      //           productFileList: this.newClickList,
      //         };
      //         const res = await addProduct(query);
      //         if (res.code == 200) {
      //           this.$message.success("新增成功!");
      //           this.$router.go(-1);
      //         } else {
      //           this.$message.error("新增失败，请稍候重试!");
      //         }
      //       } else {
      //         this.$message.error("请完善基本信息！");
      //       }
      //     }),
      //     // this.$refs.detailed 详细
      //   ]);
      // } catch (error) {
      //   return;
      // }

      // 参数
      const query = {
        productAddAndEditDto: this.editData,
        productPrice: this.pricelClickList,
        // productFile: this.newClickList,
        productFileList: this.newClickList,
      };
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.essential.$refs.ruleFormd.validate(async (valid) => {
            if (valid) {
              // 0 新增 1 编辑
              if (!this.productCode) {
                res = await addProduct(query);
                if (res.code == 200) {
                  // 在新增页赋值(companyCode存在的话，说明进入了编辑页面)
                  // if (this.productCode !== "修改成功") {
                  //   this.productCode = res.data;
                  // }
                  // 成功返回productCode;
                  this.productCode = res.data;
                  this.editData.productCode = res.data;
                  this.$message.success("新增成功!");
                  this.activeName = "second";
                } else {
                  this.$message.error("新增失败，请稍候重试!");
                }
              } else {
                res = await updateEssenti(this.editData);

                if (res.code === 200) {
                  this.$message.success("编辑成功");
                  this.activeName = "second";
                } else {
                  this.$message.error("编辑失败");
                }
              }
            } else {
              this.$message.error("请完善基本信息！");
            }
          });
          break;
        case "second":
          this.$refs.detailed.$refs.detaildForm.validate(async (valid) => {
            if (valid) {
              // 0 新增 1 编辑
              if (!this.productCode) {
                // 处理应用价值
                this.editData.informationProductValues.forEach((item) => {
                  if(item.input1&&item.input2){
                    item.value = item.input1 + "," + item.input2;
                  }
                 
                });
                res = await addProduct(query);

                if (res.code == 200) {
                  this.productCode = res.data;
                  this.editData.productCode = res.data;
                  this.$message.success("新增成功!");
                  this.activeName = "third";
                } else {
                  this.$message.error("新增失败，请稍候重试!");
                }
              } else {
                // 处理应用价值
                this.editData.informationProductValues.forEach((item) => {
                  
                  if(item.input1&&item.input2){
                    item.value = item.input1 + "," + item.input2;
                  }
                });

                // 第一个页面保存成功后，第二个页面修改需要带id
                if (this.productCode !== "修改成功") {
                  this.editData.productCode = this.productCode;
                }
                res = await updateEssenti(this.editData);

                if (res.code === 200) {
                  this.$message.success("编辑成功");
                  this.activeName = "third";
                } else {
                  this.$message.error("编辑失败");
                }
              }
            } else {
              this.$message.error("请完善基本信息！");
            }
          });
          break;
        case "third":
          this.activeName = "four";
          break;
        case "four":
          // let res = {
          //   keywordVersion: this.$refs.labelInfo.keywordVersion,
          //   productId: this.$refs.labelInfo.companyId,
          // };
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.productLabelParam.productKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let productLabelList = [];
              // 遍历我添加的数组
              item.productLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      productLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                productLabelList: productLabelList,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let productLabelParam = {
            keywordVersion:
              this.$refs.labelInfo.productLabelParam.keywordVersion, //关键字版本
            productId: this.productCode, //产品主键
            productKeywordAddParamList: arr,
          };
          if (!this.productCode) {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                // 如果是新增
                if (!this.enterpriseType) {
                  res = await insertProductLabel(productLabelParam);
                  if (res.code == 200) {
                    this.$message.success("新增成功!");
                    this.activeName = "five";
                  } else {
                    this.$message.error("新增失败，请稍候重试!");
                  }
                } else {
                  res = await updateProductLabel(productLabelParam);
                  if (res.code == 200) {
                    this.$message.success("编辑成功!");
                    this.activeName = "five";
                  } else {
                    this.$message.error("新增失败，请稍候重试!");
                  }
                }
              }
            });
          }
          break;
        case "five":
          if (!this.productCode) {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          } else {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.productId = this.productCode;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertProductLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "six";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updatePlanLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "six";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          }
          break;
        case "six":
          break;

        default:
          break;
      }
    },
    // 数据处理
    handleData(res) {
      // if (!res.data.shareImage) {
      //   res.data.shareImage = "";
      // }
      // if (res.data.imageList) {
      //   this.imageUrlList = res.data.imageList;
      // }
      // // 0是不可以提供本地服务，1是可以提供本地服务
      // if (res.data.serviceStatus) {
      //   if (res.data.serviceStatus == 1) {
      //     this.checked1 = true;
      //   } else {
      //     this.checked1 = false;
      //   }
      // }
      // // 行业动态附件;
      // if (res.data.industryDynamicsFiles) {
      //   //有附件时
      //   this.industryDynamicsFiles = [];
      //   res.data.industryDynamicsFiles.forEach((el) => {
      //     this.industryDynamicsFiles.push({
      //       name: el,
      //     });
      //   });
      // }
      // // 判断当没绑定时;
      // if (!res.data.technologyVos) {
      //   res.data.technologyVos = [
      //     {
      //       id: "",
      //       technologyName: "",
      //     },
      //   ];
      // } else if (res.data.technologyVos.length === 0) {
      //   res.data.technologyVos = [
      //     {
      //       id: "",
      //       technologyName: "",
      //     },
      //   ];
      // }
      // if (!res.data.facilitatorResults) {
      //   res.data.facilitatorResults = [
      //     {
      //       id: null,
      //       informationName: null,
      //     },
      //   ];
      // } else if (res.data.facilitatorResults.length === 0) {
      //   res.data.facilitatorResults = [
      //     {
      //       id: "",
      //       informationName: "",
      //     },
      //   ];
      // }
      // if (!res.data.informationCompanyVoList) {
      //   res.data.informationCompanyVoList = [
      //     {
      //       id: null,
      //       companyFullName: null,
      //     },
      //   ];
      // } else if (res.data.informationCompanyVoList.length === 0) {
      //   res.data.informationCompanyVoList = [
      //     {
      //       id: "",
      //       companyFullName: "",
      //     },
      //   ];
      // }
      // if (!res.data.productResults) {
      //   res.data.productResults = [
      //     {
      //       id: null,
      //       informationName: null,
      //     },
      //   ];
      // } else if (res.data.productResults.length === 0) {
      //   res.data.productResults = [
      //     {
      //       id: "",
      //       informationName: "",
      //     },
      //   ];
      // }
      // if (!res.data.specialistResults) {
      //   res.data.specialistResults = [
      //     {
      //       id: null,

      //       specialistName: null,
      //     },
      //   ];
      // } else if (res.data.specialistResults.length === 0) {
      //   res.data.specialistResults = [
      //     {
      //       id: "",
      //       specialistName: "",
      //     },
      //   ];
      // }
      // if (!res.data.informationCompanies) {
      //   res.data.informationCompanies = [
      //     {
      //       id: null,
      //       companyFullName: null,
      //     },
      //   ];
      // } else if (res.data.informationCompanies.length === 0) {
      //   res.data.informationCompanies = [
      //     {
      //       id: "",
      //       companyFullName: "",
      //     },
      //   ];
      // }
      // 产品价值
      // if (!res.data.informationProductValues) {
      //   res.data.informationProductValues = [
      //     {
      //       id: "",
      //       informationId: this.activeId,
      //       value: "",
      //       valueTitle: "",
      //     },
      //   ];
      // } else if (res.data.informationProductValues.length === 0) {
      //   res.data.informationProductValues = [
      //     {
      //       id: "",
      //       informationId: this.activeId,
      //       value: "",
      //       valueTitle: "",
      //     },
      //   ];
      // } else {
      //   res.data.informationProductValues.forEach((el) => {
      //     if (el.value) {
      //       this.$set(el, "input1", "");
      //       this.$set(el, "input2", "");
      //       el.value = el.value.split(",");
      //       el.input1 = el.value[0];
      //       el.input2 = el.value[1];
      //     }
      //   });
      // }
      // if (res.data.generalVersion == "0") {
      //   this.checked = true;
      // }
      // 产品亮点
      if (!res.data.productLightSpots) {
        this.editData.productLightSpots = "";
      }
      // 硬件说明
      if (!res.data.productHexPlains) {
        this.editData.productHexPlains = "";
      }
      // 合作案列
      if (!res.data.productCases) {
        this.editData.productCases = "";
      }
      // 产品功能
      if (!res.data.informationProducts) {
        res.data.informationProducts = [
          {
            id: null,
            informationId: this.activeId,
            productFunctionDescribe: null,
            productImage: "",
          },
        ];
      } else if (res.data.informationProducts.length === 0) {
        res.data.informationProducts = [
          {
            id: null,
            informationId: this.activeId,
            productFunctionDescribe: null,
            productImage: "",
          },
        ];
      }
      this.editData = res.data;
    },
    // 查询
    async seach() {
      // 参数-表格序号
      const queryInfo = {
        productCode: this.productCode,
      };
      const res = await getFindOne(queryInfo);
      if (res.code == 200) {
        this.handleData(res);
        this.editData = res.data;
        this.productType=this.editData.productType
        //产品类型转数组
        // this.editData.productType = this.formatString(this.editData.productType)
        //技术驱动转数组
        // this.editData.productAi = this.formatString(this.editData.productAi);
        //交付类型转数组
        this.editData.solutionDelivery = this.formatString(
          this.editData.solutionDelivery
        );
        //行业字符串转数组
        this.editData.solutionIndustry = this.formatString(
          this.editData.solutionIndustry
        );
        //领域字符串转数组
        this.editData.domainCategory = this.formatString(
          this.editData.domainCategory
        );
        //技术字符串转数组
        this.editData.solutionTechnology = this.formatString(
          this.editData.solutionTechnology
        );

        if (!this.editData.productScenes) {
          this.editData.productScenes = [""];
        }

        if (!this.editData.informationProductValues) {
          this.editData.informationProductValues[0].input1 = "";
          this.editData.informationProductValues[0].input2 = "";
        }

        if (!this.editData.productAi) {
          this.editData.productAi = []
        }
      }
    },
    // 产品标签信息详情
    async selectProductLabel() {
      const res = await selectProductLabel({
        productId: this.productCode,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.productKeywordAddParamList) {
          this.$refs.labelInfo.productLabelParam = {
            productId: "", // 产品主键
            keywordVersion: "", // 关键字版本
            type: null,
            productKeywordAddParamList: [
              {
                productLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    productKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.productLabelParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.productKeywordAddParamList.forEach((item) => {
            item.productLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 4 && this.productCode) {
        // this.$refs.labelManagement.getBylabelId();
      }
      // console.log( this.productType);
      
      // this.productType=this.editData.productType
    },
  },
};
</script>

<style lang="less" scoped>
.editProduct_type {
  padding: 0 20px;
}
.editProduct {
  overflow: hidden;
  background-color: #fff;
  /deep/ .el-tabs__content {
    overflow: auto;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
