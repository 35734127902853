import request from '@/utils/request'

// 查询
export function getProductList(data) {
  return request({
    url: '/system/product/listPage',
    method: 'post',
    data
  })
}

//查询单个
export function getFindOne(data) {
  return request({
    url: '/system/product/findOne',
    method: 'get',
    params: data
  })
}

//获取产品价格币种的枚举
export function getCurrency(data) {
  return request({
    url: '/system/code/getCodeByStatus',
    method: 'get',
    params: data
  })
}

//查询产品价格
export function getPrice(data) {
  return request({
    url: '/system/productPrice/listAll',
    method: 'get',
    params: data
  })
}


//新增产品价格信息
export function addPriceList(data) {
  return request({
    url: '/system/productPrice/save',
    method: 'post',
    data
  })
}

//修改产品价格信息
export function upDataPriceList(data) {
  return request({
    url: '/system/productPrice/update',
    method: 'put',
    data
  })
}


//删除产品价格信息
export function deletePriceList(data) {
  return request({
    url: '/system/productPrice/deleteById',
    method: 'delete',
    params: data
  })
}


//获取产品附件表格数据
export function enclosureData(data) {
  return request({
    url: '/system/productFile/findList',
    method: 'get',
    params: data
  })
}

//获取产品附件表格数据
export function smartFind(data) {
  return request({
    url: '/system/product/getByCompanyName',
    method: 'get',
    params: data
  })
}

//新增产品附件
export function addFile(data) {
  return request({
    url: '/system/productFile/save',
    method: 'post',
    data
  })
}

//删除产品附件
export function deleteFile(data) {
  return request({
    url: '/system/productFile/deleteById',
    method: 'post',
    data
  })
}

//修改产品附件信息
export function updataFile(data) {
  return request({
    url: '/system/productFile/update',
    method: 'put',
    data
  })
}

//查看单个产品附件
export function getFindId(data) {
  return request({
    url: '/system/productFile/findById',
    method: 'get',
    params: data
  })
}

//下载单个附件
export function getUrlStr(data) {
  return request({
    url: '/system/public/downloadUrlFile',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}


//编辑产品附件详细信息
export function saveDetails(data) {
  return request({
    url: '/system/product/saveDetails',
    method: 'post',
    data
  })
}

//编辑产品附件售后说明
export function saveAfterSales(data) {
  return request({
    url: '/system/product/saveAfterSales',
    method: 'post',
    data
  })
}


//删除产品数据
export function deleteProduct(data) {
  return request({
    url: '/system/product/delete',
    method: 'post',
    data
  })
}


//新增产品数据
export function addProduct(data) {
  return request({
    url: '/system/product/save',
    method: 'post',
    data
  })
}

//企业名称查询
export function getEnterpriseAll(data) {
  return request({
    url: '/system/product/listAll ',
    method: 'get',
    params: data
  })
}

//产品基本信息修改
export function updateEssenti(data) {
  return request({
    url: '/system/product/update',
    method: 'post',
    data
  })
}

//获取产品的产品类型
export function getProductType(data) {
  return request({
    url: '/system/code/getCodeByStatus',
    method: 'get',
    params: data
  })
}


//数据上传产品附件
export function uploadProduct(data) {
  return request({
    url: '/system/product/importPerson',
    method: 'post',
    data
  })
}

//产品首页下载附件
// export function downloadProductFile(data) {
//   return request({
//     url: '/system/productFile/findByProductFileKey',
//     method: 'get',
//     params: data
//   })
// }

//产品首页下载附件
export function downloadProductFile(data) {
  return request({
    url: '/system/product/findByProductFileKey',
    method: 'get',
    params: data
  })
}

//产品-模糊查询所有场景知识数据
export function selectByScenarioName(data) {
  return request({
    url: '/system/product/selectByScenarioName',
    method: 'get',
    params: data
  })
}

// 产品标签-新增
export function insertProductLabel(data) {
  return request({
    url: '/system/productLabel/insertProductLabel',
    method: 'post',
    data
  })
}

// 产品标签信息详情
export function selectProductLabel(data) {
  return request({
    url: '/system/productLabel/selectProductLabelDetail',
    method: 'get',
    params: data
  })
}

// 编辑产品标签
export function updateProductLabel(data) {
  return request({
    url: '/system/productLabel/updateProductLabel',
    method: 'post',
    data
  })
}

// 新增手动标签
export function insertProductLabelManual(data) {
  return request({
    url: '/system/productLabel/insertProductLabelManual',
    method: 'post',
    data
  })
}

// 编辑手动打标签
export function updatePlanLabelManual(data) {
  return request({
    url: '/system/productLabel/updatePlanLabelManual',
    method: 'post',
    data
  })
}

// 手动打标签查询
export function getById(data) {
  return request({
    url: '/system/productLabel/getById',
    method: 'get',
    params: data
  })
}

// 半自动打标签新增
export function insertMatchProductLabel(data) {
  return request({
    url: '/system/label/insertMatchProductLabel',
    method: 'post',
    data
  })
}

// 半自动打标签编辑
export function updateMatchedProductLabel(data) {
  return request({
    url: '/system/label/updateMatchedProductLabel',
    method: 'post',
    data
  })
}

// 半自动打标签查询
export function findHistoryMatchedLabelByProduct(data) {
  return request({
    url: '/system/label/findHistoryMatchedLabelByProduct',
    method: 'get',
    params: data
  })
}
